import React, { useMemo } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';
import { ResReports } from '../report.api';
import { GraphType } from '../report.constant';

interface Props {
  report: ResReports;
  selectedGraph: GraphType;
}

const ReportTimelineChart: React.FC<Props> = ({ report, selectedGraph }) => {
  const data = useMemo(() => {
    switch (selectedGraph) {
      case 'useCount':
        return report.teamUsageReports.map(r => ({
          date: r.targetOn,
          threadNum: r.threadNum,
          postCount: r.postCount,
          aiGenerateCount: r.aiGenerateCount,
        }));
      case 'chars':
        return report.teamUsageReports.map(r => ({
          date: r.targetOn,
          inputChars: r.inputCharsToAi,
          outputChars: r.outputChars,
          total: r.inputCharsToAi + r.outputChars,
        }));
      case 'credit':
        return report.teamUsageReports.map(r => ({
          date: r.targetOn,
          inputCredit: Number(r.inputCredit).toFixed(2),
          outputCredit: Number(r.outputCredit).toFixed(2),
          total: Number(r.totalCredit).toFixed(2),
        }));
      case 'memberNum':
        return report.teamMembershipNumReports.map(r => ({
          date: r.targetOn,
          memberNum: r.memberNum,
        }));
    }
  }, [report, selectedGraph]);

  const lines = useMemo(() => {
    switch (selectedGraph) {
      case 'useCount':
        return (
          <>
            <Bar dataKey="threadNum" name="スレッド作成" fill="#FF6347" />
            <Bar dataKey="postCount" name="メッセージ投稿" fill="#4682B4" />
            <Bar dataKey="aiGenerateCount" name="AIの回答" fill="#3CB371" />
          </>
        );
      case 'chars':
        return (
          <>
            <Bar stackId="a" dataKey="outputChars" name="出力" fill="#3CB371" />
            <Bar stackId="a" dataKey="inputChars" name="入力" fill="#FF6347" />
          </>
        );
      case 'credit':
        return (
          <>
            <Bar stackId="a" dataKey="outputCredit" name="出力" fill="#3CB371" />
            <Bar stackId="a" dataKey="inputCredit" name="入力" fill="#FF6347" />
          </>
        );
      case 'memberNum':
        return <Bar dataKey="memberNum" name="メンバー数" fill="#4682B4" />;
    }
  }, [selectedGraph]);

  const CustomTooltip = ({ active, payload, label }: { active?: boolean, payload?: any[], label?: string }) => {
    if (active && payload && payload.length) {
      const isStack = ['chars', 'credit'].includes(selectedGraph);
      const items = isStack ? payload.slice().reverse() : payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '15px', borderRadius: '5px' }}>
          <p className="label" style={{ margin: 0, marginBottom:"10px", }}>{`${label}`}</p>
          {isStack && (
            <p className="total" style={{ margin: 0, marginBottom: "5px" }}>{`合計: ${payload[0].payload.total}`}</p>
          )}
          {items.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color, margin: 0, marginBottom: "5px" }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <XAxis dataKey="date" />
        <YAxis allowDecimals={selectedGraph !== 'memberNum'} />
        <Tooltip content={<CustomTooltip />} />
        {lines}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReportTimelineChart;
