import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { PostMessageRequest, ThreadCreateType, UserMessage } from '../thread.type';
import { UserMessageType } from '../thread.constant';
import MessageFormContainer from '../containers/MessageFormContainer';
import QpFormContainer from '../../quickPrompt/containers/QpFormContainer';
import useThreadApi from '../hooks/useThreadApi';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { useThreadAlertModal } from '../hooks/useThreadAlertModal';
import useLoading from '../../generic/hooks/useLoading';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { hasMentionInTiptapData, isEmptyWithoutMention } from '@/common/utils/tiptap';
import { getMessageNode, getParentNode, handleDlpCheck } from '../thread.utils';

interface UserMessageEditModalProps {
  open: boolean;
  onClose: () => void;
  userMsg: UserMessage;
}

const UserMessageEditModal: React.FC<UserMessageEditModalProps> = ({ open, onClose, userMsg }) => {
  const { postMessage, stopGeneration } = useThreadApi();
  const { setErrorModal } = useErrorModal();
  const { setConfirmModal } = useConfirmModal();
  const {
    showQuotaExceededModal,
  } = useThreadAlertModal();
  const [ inputMessage, setInputMessage ] = useState<object | undefined>(undefined);
  const [ qpFormData, setQpFormData ] = useState<object>(
    userMsg.type == UserMessageType.NORMAL ? userMsg.body : {}
  );
  const { setIsLoading } = useLoading();

  const threadOnGenerating = useSelector((state: RootState) => state.thread.threadOnGenerating);

  const currentThread = useSelector((state: RootState) => state.thread.currentThread);
  if (!currentThread) return null;

  // このユーザーメッセージも含めた全ての兄弟ノードを取得する
  const userMsgNode = getMessageNode(currentThread, userMsg);
  const parentNode = getParentNode(currentThread, userMsgNode);
  if (!(userMsgNode && parentNode)) return null;

  const inputMessageChanged = (newValue: object | undefined) => {
    setInputMessage(newValue);
  };

  const handleSendMessage = async () => {
    // ここでメッセージ送信の処理を実装
    console.debug('Sending message:', inputMessage);
    setIsLoading(true);

    const postMessageWrapper = (skipDlpBlock: boolean = false) => {
      let request : PostMessageRequest | undefined =  undefined

      // 通常のメッセージ
      if (userMsg.type == UserMessageType.NORMAL) {
        let _inputMessage = inputMessage;

        // 何も変更されていない場合はundefinedになるので、その場合は現在の値をセット
        if (_inputMessage == undefined) {
          _inputMessage = userMsg.body;
        }
        if (isEmptyWithoutMention(_inputMessage)) {
          setIsLoading(false);
          setErrorModal(
            "メッセージが空です",
            hasMentionInTiptapData(_inputMessage) ?
            "メンション以外のメッセージを入力してから送信してください。" :
            "メッセージを入力してから送信してください。"
          )
          return;
        }
        request = {
          type: ThreadCreateType.NORMAL,
          normalDetail: {
            message: _inputMessage,
          },
          skipDlpBlock: skipDlpBlock,
          parentNodeId: parentNode.id,
        }
      } else {
        request = {
          type: ThreadCreateType.QUICK_PROMPT,
          quickPromptDetail: {
            versionId: userMsg.quickPromptVersion?.id as string,
            formInput: Object.keys(qpFormData).length === 0 ? userMsg.body : qpFormData,
          },
          skipDlpBlock: skipDlpBlock,
          parentNodeId: parentNode.id,
        }
      }

      postMessage(
        currentThread.id,
        request,
        {
          onMessageNodesAdded: () => {
            setIsLoading(false);
          },
          onBlockedByDlp: (result) => handleDlpCheck(
            result, postMessageWrapper, setErrorModal, setConfirmModal
          ),
          onQuotaExceeded: (data) => showQuotaExceededModal(data.quotaCheckResult),
          onClose: () => {
            setIsLoading(false);
          },
        }
      );
    };

    postMessageWrapper();
  };

  const handleStopGeneration = () => {
      stopGeneration();
  }

  const formChangedCallback = (formData: object) : void => {
    setQpFormData(formData);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{userMsg.type === UserMessageType.NORMAL ? 'メッセージ編集' : 'クイックプロンプト編集'}</DialogTitle>
      <DialogContent>
        {userMsg.type === UserMessageType.NORMAL ? (
          <MessageFormContainer
            value={userMsg.body}
            onChange={inputMessageChanged}
            onSubmit={handleSendMessage}
            onStop={handleStopGeneration}
            onGenerating={threadOnGenerating}
            hideSubmitButton={true}
            disableGeneratingMessage={true}
          />
        ) :
        (
          userMsg.quickPromptVersion && <QpFormContainer
            quickPromptId={userMsg.quickPromptVersion.quickPrompt.id}
            versionId={userMsg.quickPromptVersion.id}
            input={userMsg.body as { [key: string]: unknown }}
            hideSendButton={true}
            formChangedCallback={formChangedCallback}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">キャンセル</Button>
        <Button onClick={handleSendMessage} color="primary">送信</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserMessageEditModal;