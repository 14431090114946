import React, { useRef, useState } from 'react';
import { MessageNode, UserMessage } from '../thread.type';
import { Avatar, IconButton, Typography, Menu, MenuItem, Button, Box } from '@mui/material';
import { MessageType, UserMessageType } from '../thread.constant';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@/store';
import { PostInfoWrapper } from './ThreadPost';
import { AIModelConsts } from '../../aiModel/aiModel.constant';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import HardBreak from '@tiptap/extension-hard-break';
import Paragraph from '@tiptap/extension-paragraph';
import Mention from '@tiptap/extension-mention';
import { getSuggestion } from './MessageForm/suggestion';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import styled from '@emotion/styled';
import { convertTiptapDataToText, convertTiptapDataToTextWithoutMention } from '@/common/utils/tiptap';
import { getChildrenNodes, getLatestLeafNode, getMessageNode, getParentNode, restoreMask } from '../thread.utils';
import ThreadPostWordReplacedInfo from './ThreadPostReplacementInfo';
import { displayTimestamp } from '@/lib/utils';
import theme from '@/theme';
import { copyToClipboard, getOuterHtmlFromElement } from '@/common/utils/copy';
import { useToast } from '../../generic/hooks/useToast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonPreModal from '@/components/ui/CommonPreModal';
import EditIcon from '@mui/icons-material/Edit';
import UserMessageEditModal from '../components/UserMessageEditModal';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { updateCurrentNode } from '../thread.slice';
import apiAxios from '@/lib/axios';

interface ThreadPostUserMessageProps {
  userMsg: UserMessage;
  node: MessageNode;
}

const ThreadPostUserMessageWrapper = styled.div`
  margin-bottom: 10px;

  ${theme.breakpoints.up('md')} {
    max-width: min(1000px, 100%);
  }
`

const UserPostWrapper = styled.div`
  padding: 10px;
  border-radius: 10px;
  align-self: flex-end;
  background-color: #f0f4f9;
  border: none;
  display: flex;
  flex-direction: column;
  position: relative;

  .hover-content {
    display: none;
  }
  &:hover .hover-content,
  .hover-content.open {
    display: block;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: ${theme.palette.text.secondary};
  justify-content: flex-end;
`;

const PaginationButton = styled(IconButton)`
  padding: 4px;
  color: ${theme.palette.text.secondary};
`;

export const EditorArea = styled.div`
  position: relative;
  width: 100%;

  .ProseMirror {
    font-size: 1rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    cursor: text;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding: 16px 14px;

    // 折り返しされるようにする
    max-width: 100%;
    word-wrap: break-word;
    white-pace: break-space;
    overflow-wrap: break-word;
    line-break: anywhere;
    word-break: break-all;
    overflow-wrap: break-word;
    overflow-wrap: anywhere;
  }

  // pタグはmarginなし
  .ProseMirror p{
    margin: 0;
  }

  // メンションの装飾
  .ProseMirror span[data-type="mention"] {
    color: ${theme.palette.primary.main};
  }

`;

const ItemLabel = styled.div`
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
`;

const ItemValue = styled.div`
  margin-bottom: 16px;
  &.open {
    white-space: pre-wrap;
  }
`;

const UserMessageContentWrapper = styled.div`
  position: relative;
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.3s ease;

  &.open {
    max-height: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background: linear-gradient(to bottom, rgba(240, 244, 249, 0), rgba(240, 244, 249, 1));
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  &.open::after {
    opacity: 0;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #1976d2;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 8px 0;
  margin-left: auto;
`;

// 条件付きHooks呼び出しをさけるためコンポーネントを分割
const UserMessageNormal: React.FC<{ userMsg: UserMessage }> = ({ userMsg }) => {
  const editor = useEditor({
    editable: false,
    extensions: [
      Document,
      HardBreak,
      Paragraph,
      Text,
      Mention.configure({
        suggestion: getSuggestion()
      })],
    content: userMsg.body,
  }) as Editor;

  return (
    <EditorArea>
      <EditorContent editor={editor} />
    </EditorArea>
  );
};


const ThreadPostUserMessage: React.FC<ThreadPostUserMessageProps> = ({ userMsg, node }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isRealQpOpen, setIsRealQpOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentThread = useSelector((state: RootState) => state.thread.currentThread);
  const copyAnchor = useRef<HTMLDivElement>(null);
  const { showToast } = useToast();

  if (!loginUser || !currentThread) return <></>;

  const isNormalMsg = userMsg.type == UserMessageType.NORMAL;
  const isQpMsg = userMsg.type == UserMessageType.QUICK_PROMPT;

  // このユーザーメッセージも含めた全ての兄弟ノードを取得する
  const userMsgNode = getMessageNode(currentThread, userMsg);
  const parentNode = getParentNode(currentThread, userMsgNode);
  const siblingNodes = getChildrenNodes(currentThread, parentNode);
  console.log({
    userMsgNode,
    parentNode,
    siblingNodes,
  })
  if (!(userMsgNode && parentNode && siblingNodes.length > 0)) return null;

  // このユーザーメッセージのindexを取得
  const userMsgIndex = siblingNodes.findIndex(node => node.id == userMsgNode.id);

  // 前後のノードを取得
  const prevNode = siblingNodes[userMsgIndex - 1];
  const nextNode = siblingNodes[userMsgIndex + 1];

  // ページングの情報
  const totalPages = siblingNodes.length;
  const currentPage = userMsgIndex + 1;


  // Qpの入力内容を下記のようなオブジェクトにして返す。
  // {
  //    "要約したい文章": "XXXXXXXXXXXXX",
  //    "どのモデルに要約させますか？": "XXX,XXX,XXX,XXX",
  //    "他にご要望はありますか？": "XXXXXXXXXXXXX",
  // }
  const buildQpData = () => {
    const version = userMsg.quickPromptVersion;
    const formSchema = version?.formSchema;
    const uiSchema = version?.uiSchema;

    const data: Record<string, string> = {};

    const getKeyLabel = (key: string) : string => {
      if (!formSchema) {
        return key
      }
      if ('properties' in formSchema) {
        const properties = formSchema['properties'] as Record<string, { title?: string }>;
        if (key in properties) {
          const property = properties[key] as object;
          if ('title' in property) {
            return property['title'] as string;
          }
        }
      }
      return key;
    }

    const toStr = (val: string | string[] | number | number[] ) : string => {
      let str = "";
      if (Array.isArray(val)) {
        str = val.join(',');
      }
      else if (typeof val === 'number') {
        str = val.toString();
      }
      else {
        str = val;
      }

      return str;
    }

    const getValue = (key: string, value: string | string[] | number | number[]) : string => {
      if (key === 'aiModelCodes') {
        const aiModelNames = (value as string[]).map((id) => {
          const aiModel = AIModelConsts.find((model) => model.id === id);
          return aiModel ? aiModel.name : id;
        });
        return aiModelNames.join(', ');
      }
      return toStr(value);
    }

    let order : string[] = [];
    if (uiSchema && 'ui:order' in uiSchema) {
      order = uiSchema['ui:order'] as string[];
    }

    if (order && order.length > 0) {
      order.forEach((key) => {
        if (key in userMsg.body) {
          const value = userMsg.body[key as keyof typeof userMsg.body];
          data[getKeyLabel(key)] = getValue(key, value);
        }
      });
    } else {
      Object.entries(userMsg.body).forEach(([key, value]) => {
        data[getKeyLabel(key)] = getValue(key, value as string | string[] | number | number[]);
      });
    }

    return data;
  };

  // クリップボードにコピー
  const copy = () => {
    if (copyAnchor.current) {
      let textHtml = "";
      let textPlain = "";
      if (isNormalMsg) {
        textHtml = getOuterHtmlFromElement(copyAnchor.current);
        textPlain = convertTiptapDataToText(userMsg.body);
      } else {
        const data = buildQpData();
        textPlain = Object.keys(data).map((key) => {
          return `[${key}]\n${data[key]}\n`
        }).join("\n")
        textHtml = Object.keys(data).map((key) => {
          return `<h3>${key}</h3><pre>${data[key]}</pre>`
        }).join("<br/>");
      }
      copyToClipboard(
        textPlain,
        textHtml,
        showToast,
      );
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const changePage = (siblingNode: MessageNode) => {
    const leafNode = getLatestLeafNode(currentThread, siblingNode);
    if (leafNode) {
      dispatch(updateCurrentNode(leafNode.id));
      setTimeout(() => {
        apiAxios.put(
          `/threads/${currentThread.id}/current-message-node`,
          { messageNodeId: leafNode.id }
        );
      }, 0);
    }

  }

  const handlePrevPage = () => {
    if (currentPage > 1 && prevNode) {
      changePage(prevNode);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages && nextNode) {
      changePage(nextNode);
    }
  };

  const replaced = restoreMask(userMsg.sendPrompt, currentThread.dlpReplaces)
  const info = (
    <PostInfoWrapper>
      <Avatar src={loginUser.picture} />
      <Typography variant="body2" color="textSecondary">
        <b>あなた</b> &nbsp;&nbsp;{displayTimestamp(node.createdAt)}
      </Typography>
      {/* ワード置換情報 */}
      {(() => {
        if (replaced === userMsg.sendPrompt) {
          return <></>
        }

        return (
          <ThreadPostWordReplacedInfo type={MessageType.USER} body={userMsg.sendPrompt} />
        )
      })()}
      <div style={{ flexGrow: 1 }} />
      <Box className={`hover-content ${isMenuOpen ? 'open' : ''}`}>
        <IconButton size="small" onClick={handleEditModalOpen}>
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={copy}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
        {
          isQpMsg && <>
            <IconButton size="small" onClick={handleMenuOpen}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => {
                setIsRealQpOpen(true);
                handleMenuClose();
              }}>構築されたプロンプトを見る</MenuItem>
            </Menu>
            <CommonPreModal
              open={isRealQpOpen}
              title={<>
                クイックプロンプトで構築されたプロンプト<br/>
                <Typography variant="caption" color="textSecondary">
                  クイックプロンプトによって下記のプロンプトが構築されました。
                  こちらの内容に各種処理を加えた上で、生成AIモデルに送信しています。
                </Typography>
              </>}
              onClose={() => setIsRealQpOpen(false)}
              additionalActions={<>
                <Button onClick={() => copyToClipboard(replaced, undefined, showToast)}>
                  コピーする
                </Button>
              </>}
              >
              {replaced}
            </CommonPreModal>
          </>
        }
      </Box>
    </PostInfoWrapper>
  );

  let textContentLength = 0;
  let content = <></>;
  if (isNormalMsg) {
    content = <UserMessageNormal userMsg={userMsg} />;
    textContentLength = convertTiptapDataToTextWithoutMention(userMsg.body).length;
  }

  if (isQpMsg) {
    const data = buildQpData();
    content = (
      <div style={{marginTop: 15}}>
        {Object.keys(data).map((key) => {
          return (
            <React.Fragment key={key}>
              <ItemLabel>{key}</ItemLabel>
              <ItemValue className={isOpen ? 'open' : ''}>{data[key]}</ItemValue>
            </React.Fragment>
          );
        })}
      </div>
    );
    // dataの文字数をカウント
    textContentLength = Object.values(data).reduce((sum, value) => sum + (value.length || 0), 0);
  }

  const isLongContent = textContentLength > 300;

  return (
    <ThreadPostUserMessageWrapper>
      <UserPostWrapper key={userMsg.id}>
        {info}
        <UserMessageContentWrapper className={isOpen || !isLongContent ? 'open' : ''}>
          <div ref={copyAnchor}>{content}</div>
        </UserMessageContentWrapper>

        {isLongContent && (
          <ToggleButton onClick={() => setIsOpen(!isOpen)}>{isOpen ? '閉じる' : '開く'}</ToggleButton>
        )}

        <UserMessageEditModal open={isEditModalOpen} onClose={handleEditModalClose} userMsg={userMsg} />
      </UserPostWrapper>
      {
        totalPages > 1 && <PaginationWrapper>
          <PaginationButton
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            disableRipple={true}
          >
            <ChevronLeftIcon />
          </PaginationButton>
          <span>{`${currentPage} / ${totalPages}`}</span>
          <PaginationButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            disableRipple={true}
          >
            <ChevronRightIcon />
          </PaginationButton>
        </PaginationWrapper>
      }
    </ThreadPostUserMessageWrapper>
  );
};

export default ThreadPostUserMessage;
