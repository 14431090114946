import React from 'react';
import { Grid, Typography, Paper, useTheme, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { ResReports } from '../report.api';

interface Props {
  report: ResReports;
}

const ReportSummary: React.FC<Props> = ({ report }) => {
  const theme = useTheme();

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const sections = [
    {
      title: '利用回数',
      items: [
        { label: 'スレッド作成回数', value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.threadNum, 0)), unit: '件' },
        { label: 'メッセージ投稿回数', value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.postCount, 0)), unit: '件' },
        { label: 'AIからの回答回数', value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.aiGenerateCount, 0)), unit: '件' },
      ],
    },
    {
      title: 'クレジット数',
      items: [
        { label: '合計', value: formatNumber(Math.floor(report.teamUsageReports.reduce((sum, r) => sum + Number(r.totalCredit), 0) * 10) / 10), unit: '' },
        { label: '入力のみ', value: formatNumber(Math.floor(report.teamUsageReports.reduce((sum, r) => sum + Number(r.inputCredit), 0) * 10) / 10), unit: '' },
        { label: '出力のみ', value: formatNumber(Math.floor(report.teamUsageReports.reduce((sum, r) => sum + Number(r.outputCredit), 0) * 10) / 10), unit: '' },
      ],
    },
    {
      title: '文字数',
      items: [
        { label: '合計', value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.inputCharsInView + r.outputChars, 0)), unit: '文字' },
        { label: '入力のみ', value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.inputCharsInView, 0)), unit: '文字' },
        { label: '出力のみ', value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.outputChars, 0)), unit: '文字' },
      ],
    },
  ];

  return (
    <>
      <Grid container spacing={4} sx={{mb: 3}}>
        <Grid item xs={12} sm={6} md={6} lg={3} >
          <Paper sx={{
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h6">
                  利用メンバー数
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'right', paddingRight:'15px'}}>
                <Typography variant="h6">
                  {formatNumber(report.uniqueMembershipNum)} 人
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {sections.map((section, sectionIndex) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={`section-${sectionIndex}`}>
            <Paper elevation={3} sx={{ padding: theme.spacing(3), height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                {section.title}
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    {section.items.map((item, itemIndex) => (
                      <TableRow key={`${sectionIndex}-${itemIndex}`}>
                        <TableCell>{item.label}</TableCell>
                        <TableCell align="right">
                          {item.value} {item.unit}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ReportSummary;
