import React from 'react';
import { useEditor, Editor, Content } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import HardBreak from '@tiptap/extension-hard-break';
import Text from "@tiptap/extension-text";
import Mention from '@tiptap/extension-mention'
import MessageFormPresenter from '../presenters/MessageFormPresenter';
import { getSuggestion } from './MessageForm/suggestion';
import Placeholder from '@tiptap/extension-placeholder'
import { useMediaQuery, useTheme } from '@mui/material';
import History from '@tiptap/extension-history';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership } from '../../auth/auth.type';
import { isThreadDetailPage } from '@/lib/utils';

interface ThreadMessageFormProps {
  value: object | undefined;
  onChange: (value: object | undefined) => void;
  onSubmit: () => void;
  onStop: () => void;
  onGenerating: boolean;
  // オプション
  hideSubmitButton?: boolean;
  disableGeneratingMessage?: boolean;
}

export const MessageFormContainer: React.FC<ThreadMessageFormProps> = ({
  value,
  onChange,
  onSubmit,
  onStop,
  onGenerating,
  hideSubmitButton = false,
  disableGeneratingMessage = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)

  let aiModelCodes: string[] | undefined = undefined;
  if (membership) {
    aiModelCodes = membership.team.quota.usableAiCodes
  }

  const onSubmitRef = React.useRef(onSubmit);

  React.useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit]);

  const editor = useEditor({
    extensions: [
      Placeholder.configure({placeholder: () => {
        const isThreadUrl = isThreadDetailPage();
        return (
          (isThreadUrl ? 'AIに追加で指示する' : 'クイックプロンプトなしでAIに指示する') +
          (isMobile ? "" : " （Enterで改行 Ctrl+Enterで送信）")
        )
      }}),
      Document,
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => {
              if (!this.editor.can().setHardBreak()) {
                return false;
              }
              return this.editor.commands.setHardBreak();
            },
            "Shift-Enter": () => {
              if (!this.editor.can().setHardBreak()) {
                return false;
              }
              return this.editor.commands.setHardBreak();
            },
            "Control-Enter": () => {
              onSubmitRef.current();
              return true;
            },
            "Meta-Enter": () => {
              onSubmitRef.current();
              return true;
            },
          }
        },
      }),
      Paragraph,
      Text,
      Mention.configure({
        suggestion: getSuggestion(
          aiModelCodes,
          membership?.mentionedAiModelCodesAtLastThreadCreation,
        )
      }),
      // undo/redoを使えるようにする
      History,

    ],
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getJSON());
    },
  }) as Editor;

  // valueのリセットを反映
  React.useEffect(() => {
    // setContentが走る度にカーソルの位置が末尾に移動してしまうので、
    // Tiptap内の値と親コンポーネントから渡された値が異なる場合のみsetContentするようにする
    if (editor && JSON.stringify(editor.getJSON()) === JSON.stringify(value)) {
      return
    }
    editor?.commands.setContent(value as Content);
  }, [value, editor]);

  // メンションリストの更新
  React.useEffect(() => {
    if (!editor) {
      return;
    }
    const extensions = editor.options.extensions;
    editor.setOptions({
      extensions: extensions.map(ext => {
        if (ext.name === 'mention') {
          return Mention.configure({
            suggestion: getSuggestion(
              aiModelCodes,
              membership?.mentionedAiModelCodesAtLastThreadCreation,
            ),
          });
        }
        return ext;
      })
    })
  }, [loginUser, editor]);

  if (!editor) {
    return null;
  }

  return <MessageFormPresenter
    editor={editor}
    onSubmit={onSubmit}
    onStop={onStop}
    onGenerating={onGenerating}
    hideSubmitButton={hideSubmitButton}
    disableGeneratingMessage={disableGeneratingMessage}
  />;
};

export default MessageFormContainer;
