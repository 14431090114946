import axios from 'axios';
import { UnitType } from './report.type';

export interface ResReports {
  teamUsageReports: ResTeamUsageReport[];
  teamUsageDetailReports: ResTeamUsageDetailReport[];
  teamMembershipNumReports: ResTeamMemberReport[];
  uniqueMembershipNum: number;
}

export interface ResTeamUsageReport {
  id: number;
  createdAt: string;
  updatedAt: string;
  teamId: number;
  targetOn: string;
  aiGenerateCount: number;
  totalCredit: string;
  inputCredit: string;
  outputCredit: string;
  inputCharsInView: number;
  inputCharsToAi: number;
  outputChars: number;
  threadNum: number;
  postCount: number;
}

export interface ResTeamUsageDetailReport {
  id: number;
  createdAt: string;
  updatedAt: string;
  teamId: number;
  targetOn: string;
  aiGenerateCount: number;
  totalCredit: string;
  inputCredit: string;
  outputCredit: string;
  inputCharsInView: number;
  inputCharsToAi: number;
  outputChars: number;
  type: string;
  aiModel: {
    id: number;
    code: string;
    name: string;
  };
}

export interface ResTeamMemberReport {
  id: number;
  createdAt: string;
  updatedAt: string;
  teamId: number;
  targetOn: string;
  memberNum: number;
}

export const getReports = async (params: { teamId: string; unitType: UnitType; termFrom: Date; termTo: Date }) => {
  const response = await axios.get<ResReports>(`/api/teams/${params.teamId}/reports`, {
    params: {
      unitType: params.unitType,
      termFrom: params.termFrom.toISOString().split('T')[0],
      termTo: params.termTo.toISOString().split('T')[0],
    },
  });
  return response;
};
